import moment, { Duration, Moment } from "moment";
import { DateTimeFormats } from "./datetime-formats";

export const DateTimeConversions = {
  unixToDateTimeString: (value: number, seconds = false): string =>
    moment(value).format(
      seconds ? DateTimeFormats.dateHMS : DateTimeFormats.dateHM
    ),
  dateTimeStringToUnix: (value: string): number =>
    moment(value, DateTimeFormats.dateHM).unix() * 1000,
  durationToHMS: (value: Duration): string => {
    const hours = value.get("hours");
    const minutes = value.get("minutes");
    const seconds = value.get("seconds");

    const pad = (num: number, size: number): string => {
      let s = num + "";

      while (s.length < size) {
        s = "0" + s;
      }

      return s;
    };

    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  },
  momentToMonthHuman: (value: Moment): string =>
    value.format(DateTimeFormats.monthHuman),
  momentToDateHuman: (value: Moment): string =>
    value.format(DateTimeFormats.dateHuman),
  momentToDateHM: (value: Moment | null): string =>
    value ? value.format(DateTimeFormats.dateHM) : "0000-00-00 00:00",
  momentToDateHMS: (value: Moment | null): string =>
    value ? value.format(DateTimeFormats.dateHMS) : "0000-00-00 00:00:00",
  dateHMToMoment: (value: string): Moment =>
    moment(value, DateTimeFormats.dateHM),
  dateHMSToMoment: (value: string): Moment =>
    moment(value, DateTimeFormats.dateHMS),
};