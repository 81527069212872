export const DateTimeFormats = {
    month: "YYYY-MM",
    monthHuman: "MMMM YYYY",
    monthMask: "####-##",
    date: "YYYY-MM-DD",
    dateHuman: "dddd MMMM Do YYYY",
    dateMask: "####-##-##",
    dateHM: "YYYY-MM-DD HH:mm",
    dateHMMask: "####-##-## ##:##",
    dateHMS: "YYYY-MM-DD HH:mm:ss",
    dateHMSMask: "####-##-## ##:##:##",
  };